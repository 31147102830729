import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  deleteCertificate,
  retriveAccountDetails,
  saveAccountDetails,
} from "../../services/CustomerService";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import { getPOSStates } from "../../services/StateService";
import { getAllCountry } from "../../services/CountryService";
// import { getCustomerCategories,getPosShipVias,getPosPaymentTypes } from "../../services/CustomerService";

const AccountDetails = () => {
  const [details, setDetails] = useState({});
  const [passwordType, setpasswordType] = useState(false);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [billingAddressCheckbox, setbillingAddressCheckbox] = useState(false);

  // const [shipVia, setShipVia] = useState([]);
  // const [paymentType, setPaymentType] = useState([]);
  // const [customerCategory, setCustomerCategory] = useState([]);
  let date = new Date();
  date =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getAccountDetails();
    getCountry();
    getPOSState(details.country_id);
    // getPosShipVia();
    // getPosPaymentType();
    // getCustomerCategory();
  }, []);

  const getAccountDetails = async () => {
    await retriveAccountDetails()
      .then((response) => {
        let data = response.data.data;
        data.mobile = formattedNumber(data.mobile);
        data.phone = formattedNumber(data.phone);
        data.manager_number = formattedNumber(data.manager_number);
        data.fax = formattedNumber(data.fax);
        setDetails(data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getPOSState = async (id) => {
    if (id != "") {
      await getPOSStates(id)
        .then((response) => {
          setStates(response.data.data);
        })
        .catch((error) => {});
    } else {
      setStates([]);
    }
  };
  // const getPosShipVia = async () => {
  //     await getPosShipVias().then((response) => {
  //         setShipVia(response.data.data);
  //     }).catch((error) => {
  //         console.log("Error:", error);
  //     });
  // }
  // const getPosPaymentType = async () => {
  //     await getPosPaymentTypes().then((response) => {
  //         setPaymentType(response.data.data);
  //     }).catch((error) => {
  //         console.log("Error:", error);
  //     });
  // }
  // const getCustomerCategory = async () => {
  //     await getCustomerCategories().then((response) => {
  //         setCustomerCategory(response.data.data);
  //     }).catch((error) => {
  //         console.log("Error:", error);
  //     });
  // }
  const getCountry = async () => {
    await getAllCountry()
      .then((response) => {
        setCountry(response.data);
      })
      .catch((error) => {});
  };

  const handleDetailsChange = (e) => {
    let { name, value } = e.target;
    if (name == "country_id" && details.state_id != value) {
      getPOSState(value);
    }

    if (
      name == "phone" ||
      name == "mobile" ||
      name == "manager_number" ||
      name == "fax"
    ) {
      if (value != null) {
        var x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        value = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      }
    }
    setDetails({ ...details, [name]: value });

    if (name == "tax_certificate" || name == "tob_certificate") {
      setDetails({ ...details, [name]: e.target.files[0] });
      return;
    }
  };

  const formattedNumber = (value) => {
    if (value != null) {
      var x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    }
  };

  const handleCertificateDelete = async (e, type) => {
    e.preventDefault();
    let data = [];
    data.id = details.pos_customer_id;
    type == "tax"
      ? (data["tax"] = details.tax_certificate)
      : (data["tob"] = details.tob_certificate);

    await deleteCertificate(data)
      .then((response) => {
        toast("Certificate Removed Successfully.", { type: "success" });

        setDetails({
          ...details,
          [type == "tax" ? "tax_certificate" : "tob_certificate"]: "",
        });
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const handleSubmit = async () => {
    if (validateInput()) {
      var phonenumber = details?.phone?.replace(/\D/g, "");
      var mobile = details?.mobile?.replace(/\D/g, "");
      var manager_number = details?.manager_number?.replace(/\D/g, "");
      var fax = details?.fax?.replace(/\D/g, "");
      let formdata = new FormData();
      // formdata.append("id", details.id);
      // formdata.append("name_prefix", details.name_prefix);
      // formdata.append("name", details.name);
      // formdata.append("email", details.email);
      // formdata.append("company_name", details.company_name);
      // formdata.append("address", details.address);
      // formdata.append("pincode", details.pincode);
      // formdata.append("city", details.city);
      // formdata.append("country_id", details.country_id);
      // formdata.append("state_id", details.state_id);
      // formdata.append("tax_id", details.tax_id ?? "");
      // formdata.append("tax_expiry_date", details.tax_expiry_date ?? "");
      // formdata.append("tax_certificate", details.tax_certificate ?? "");
      // formdata.append("phone", phonenumber ?? "");
      // formdata.append('payment_type',details.payment_type ?? '');
      // formdata.append('cc_tax_id',details.cc_tax_id);
      // formdata.append("dba", details.dba ?? "");
      // formdata.append("mobile", mobile);
      // formdata.append("manager_number", manager_number ?? "");
      // formdata.append("fax", fax ?? "");
      // formdata.append("tob_license_no", details.tob_license_no ?? "");
      // formdata.append("tob_expiry_date", details.tob_expiry_date ?? "");
      // formdata.append("tob_certificate", details.tob_certificate ?? "");
      // formdata.append('ship_via',details.ship_via ?? '');
      formdata.append("password_current", details.password_current ?? "");
      formdata.append("new_password", details.new_password ?? "");
      formdata.append(
        "new_password_confirmation",
        details.new_password_confirmation ?? ""
      );
      // formdata.append("billing_street", details.billing_street ?? "");
      // formdata.append("billing_city", details.billing_city ?? "");
      // formdata.append("billing_state", details.billing_state ?? "");
      // formdata.append("billing_zip", details.billing_zip ?? "");
      // formdata.append("billing_address", details.billing_address ?? "");
      // formdata.append("alt_email", details.alt_email ?? "");

      await saveAccountDetails(formdata)
        .then((response) => {
          toast("Details Updated Successfully.", { type: "success" });
          setDetails({
            ...details,
            password_current: "",
            new_password: "",
            new_password_confirmation: "",
          });
        })
        .catch((error) => {
          console.log("Error", error);
          if (error?.response?.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data?.error, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    // if (details.name == "" || details.name == null) {
    //   toast("Please enter name.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (details.company_name == "" || details.company_name == null) {
    //   toast("Please enter company name.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (details.email == "" || details.email == null) {
    //   toast("Please enter email.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (details.address == "" || details.address == null) {
    //   toast("Please enter addess.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (details.pincode == null || details.pincode == "") {
    //   toast("Please enter zip.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (details.mobile == "" || details.mobile == null) {
    //   toast("Please enter Primary Contact.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (details.country_id == null || details.country_id == "") {
    //   toast("Please select country.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (details.state_id == null || details.state_id == "") {
    //   toast("Please select states.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // // if (details.billing_street == "" || details.billing_street == null) {
    // //   toast("Please enter Billing street.", {
    // //     type: "error",
    // //   });
    // //   return false;
    // // }
    // if (details.billing_city == "" || details.billing_city == null) {
    //   toast("Please enter Billing city.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (details.billing_state == "" || details.billing_state == null) {
    //   toast("Please enter Billing City.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (details.billing_zip == "" || details.billing_zip == null) {
    //   toast("Please enter Billing zip.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (details.billing_address == "" || details.billing_address == null) {
    //   toast("Please enter Billing address.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    // if (
    //   (details.tax_id != null && details.tax_id != "") ||
    //   (details.tax_expiry_date != null && details.tax_expiry_date != "")
    // ) {
    //   if (
    //     details.tax_id != null &&
    //     details.tax_id != "" &&
    //     details.tax_expiry_date != null &&
    //     details.tax_expiry_date != ""
    //   ) {
    //   } else {
    //     if (details.tax_certificate == "" || details.tax_certificate == null) {
    //       toast("Please Select Tax certificate.", {
    //         type: "error",
    //       });
    //       return false;
    //     }
    //   }
    // }
    // // if(details.payment_type == null || details.payment_type == ''){
    // //     toast("Please select payment type.",{
    // //         type:'error'
    // //     });
    // //     return false
    // // }
    // // if(details.cc_tax_id == null || details.cc_tax_id == ''){
    // //     toast("Please select customer category.",{
    // //         type:'error'
    // //     });
    // //     return false
    // // }
    if (details.password_current == "" || details.password_current == null) {
      toast("Please enter current password.", {
        type: "error",
      });
      return false;
    }
    if (
      details.password_current ||
      details.new_password ||
      details.new_password_confirmation
    ) {
      if (details.password_current != "") {
        if (
          !details.hasOwnProperty("new_password") ||
          details.new_password == ""
        ) {
          toast("Please enter new password.", {
            type: "error",
          });
          return false;
        }
        if (
          !details.hasOwnProperty("new_password_confirmation") ||
          details.new_password_confirmation == ""
        ) {
          toast("Please confirm password .", {
            type: "error",
          });
          return false;
        }
        if (details.new_password != details.new_password_confirmation) {
          toast("Password do not match .", {
            type: "error",
          });
          return false;
        }
      }
    }

    return true;
  };

  const billingAddressToggle = (value) => {
    if (value) {
      setbillingAddressCheckbox(true);
      setDetails({
        ...details,
        billing_city: details?.city,
        billing_address: details?.address,
        billing_state: details?.state_id,
        billing_zip: details?.pincode,
      });
    } else {
      setbillingAddressCheckbox(false);
      setDetails({
        ...details,
        billing_city: "",
        billing_address: "",
        billing_state: "",
        billing_zip: "",
      });
    }
  };

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Account Details
          </div>
        </div>
      </div>
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <Navigation />
                <div className="col-md-9">
                  <div className="tab-content account dashboard-content">
                    <div
                      className="tab-pane fade active show"
                      id="account-detail"
                      role="tabpanel"
                      aria-labelledby="account-detail-tab"
                    >
                      <div className="card">
                        <div className="card-header">
                          <h5>Account Details</h5>
                          <span>
                            (If you need to make any changes to your account
                            details, please contact Silverline Wholesale.)
                          </span>
                        </div>
                        <div className="card-body">
                          <form method="post" name="enq">
                            <div className="row">
                              {/* <div className="form-group col-md-6">
                                <div className="row d-flex">
                                  <div className="col-xl-3 col-3">
                                    <label>
                                      Prefix <span className="required">*</span>
                                    </label>
                                    <select
                                      className="form-select"
                                      name="name_prefix"
                                      value={details.name_prefix}
                                      onChange={(e) => handleDetailsChange(e)}
                                    >
                                      <option value="Mr.">Mr.</option>
                                      <option value="Ms.">Ms.</option>
                                      <option value="Mrs">Mrs.</option>
                                      <option value="Miss.">Miss.</option>
                                    </select>
                                  </div>
                                  <div className="col-xl-9 col-9">
                                    <label>
                                      Name <span className="required">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      required=""
                                      name="name"
                                      className="form-control"
                                      value={details.name}
                                      onChange={(e) => handleDetailsChange(e)}
                                      placeholder="Name *"
                                    />
                                  </div>
                                </div>
                              </div> */}

                              <div className="form-group col-md-6">
                                <label>
                                  Name <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  disabled={true}
                                  className="form-control"
                                  name="name"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.name}
                                  type="text"
                                  placeholder="Name *"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Company Name{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  disabled={true}
                                  className="form-control"
                                  name="company_name"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.company_name}
                                  type="text"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Email Address{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="email"
                                  disabled={true}
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.email}
                                  type="email"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Second Email Address{" "}
                                  <span className="required"></span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  alt="Second Email Address"
                                  name="alt_email"
                                  disabled={true}
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.alt_email}
                                  type="email"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Username <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="username"
                                  disabled={true}
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.username}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Doing Business As</label>
                                <input
                                  type="text"
                                  required=""
                                  name="dba"
                                  disabled={true}
                                  className="form-control"
                                  value={details.dba}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Doing Business As"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Tax Expiry Date</label>
                                <input
                                  type="date"
                                  disabled={true}
                                  required=""
                                  name="tax_expiry_date"
                                  className="form-control"
                                  min={date}
                                  value={details.tax_expiry_date}
                                  onChange={(e) => handleDetailsChange(e)}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Tax Certificate</label>
                                <input
                                  type="file"
                                  disabled={true}
                                  required=""
                                  className="form-control form-control-lg"
                                  name="tax_certificate"
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Tax Certificate"
                                />
                                {details.tax_certificate != null &&
                                details.tax_certificate != "" ? (
                                  <>
                                    <a
                                      href={
                                        process.env.REACT_APP_BASE_URL +
                                        "/storage/tax_certificates/" +
                                        details.tax_certificate
                                      }
                                      target="_blank"
                                    >
                                      <img
                                        src={"/images/theme/icons/icon-eye.svg"}
                                        className="show-certificate"
                                      />
                                    </a>
                                    <a
                                      className="text-danger ml-2"
                                      href=""
                                      onClick={(e) =>
                                        handleCertificateDelete(e, "tax")
                                      }
                                      target="_blank"
                                    >
                                      <img
                                        src={
                                          "/images/theme/icons/icon-delete.svg"
                                        }
                                        className="show-delete"
                                      />
                                    </a>
                                  </>
                                ) : null}
                              </div>

                              <div className="form-group col-md-6">
                                <label>
                                  Primary Contact#{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="mobile"
                                  disabled={true}
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.mobile}
                                  type="text"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Secondary Contact#</label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="phone"
                                  disabled={true}
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.phone}
                                  type="text"
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <label>Manager Number#</label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="manager_number"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.manager_number}
                                  disabled={true}
                                  type="text"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Fax Number</label>
                                <input
                                  required=""
                                  className="form-control"
                                  disabled={true}
                                  name="fax"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.fax}
                                  type="text"
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <label>Tobacco License No</label>
                                <input
                                  type="text"
                                  required=""
                                  name="tob_id"
                                  className="form-control"
                                  disabled={true}
                                  value={details.tob_license_no}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Tobacco License No"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Tobacco Expiry Date</label>
                                <input
                                  type="date"
                                  required=""
                                  name="tob_expiry_date"
                                  className="form-control"
                                  min={date}
                                  disabled={true}
                                  value={details.tob_expiry_date}
                                  onChange={(e) => handleDetailsChange(e)}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Tobacco Certificate</label>
                                <input
                                  type="file"
                                  required=""
                                  className="form-control form-control-lg"
                                  name="tob_certificate"
                                  disabled={true}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Tobacco Certificate"
                                />
                                {details.tob_certificate != null &&
                                details.tob_certificate != "" ? (
                                  <>
                                    <a
                                      href={
                                        process.env.REACT_APP_BASE_URL +
                                        "/storage/tobacco_certificates/" +
                                        details.tob_certificate
                                      }
                                      target="_blank"
                                    >
                                      <img
                                        src={"/images/theme/icons/icon-eye.svg"}
                                        className="show-certificate"
                                      />
                                    </a>
                                    <a
                                      className="text-danger ml-2"
                                      href=""
                                      onClick={(e) =>
                                        handleCertificateDelete(e, "tob")
                                      }
                                      target="_blank"
                                    >
                                      <img
                                        src={
                                          "/images/theme/icons/icon-delete.svg"
                                        }
                                        className="show-delete"
                                      />
                                    </a>
                                  </>
                                ) : null}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Tax ID</label>
                                <input
                                  type="text"
                                  required=""
                                  name="tax_id"
                                  className="form-control"
                                  value={details.tax_id}
                                  disabled={true}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Tax ID"
                                />
                              </div>
                              <h5 style={{ marginBottom: "7px" }}>
                                Shipping Details
                              </h5>

                              <div className="form-group col-md-6">
                                <label>
                                  Shipping Country{" "}
                                  <span className="required">*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="country_id"
                                  disabled={true}
                                  value={details?.country_id}
                                  onChange={(e) => handleDetailsChange(e)}
                                >
                                  <option value="">
                                    Select Shipping Country *
                                  </option>
                                  {country.map((country, index) => {
                                    return (
                                      <option key={index} value={country.id}>
                                        {" "}
                                        {country?.country}{" "}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Shipping State{" "}
                                  <span className="required">*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="state_id"
                                  disabled={true}
                                  value={details?.state_id}
                                  onChange={(e) => handleDetailsChange(e)}
                                >
                                  <option value="">
                                    Select Shipping State *
                                  </option>
                                  {states.map((state, index) => {
                                    return (
                                      <option key={index} value={state.id}>
                                        {" "}
                                        {state.state}{" "}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              <div className="form-group col-md-6">
                                <label>
                                  Shipping City{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  type="text"
                                  required=""
                                  name="city"
                                  disabled={true}
                                  className="form-control"
                                  value={details.city}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Shipping City*"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Shipping Address{" "}
                                  <span className="required">*</span>
                                </label>
                                <textarea
                                  required=""
                                  name="address"
                                  style={{ height: "64px" }}
                                  rows={2}
                                  className="form-control"
                                  disabled={true}
                                  value={details.address}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Shipping Address"
                                ></textarea>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Shipping Zip{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  type="number"
                                  required=""
                                  disabled={true}
                                  name="pincode"
                                  className="form-control"
                                  value={details.pincode}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Shipping Zip *"
                                />
                              </div>
                              <h5>Billing Details</h5>

                              <div
                                className="login_footer form-group  "
                                style={{ marginBottom: "7px" }}
                              >
                                <div className="chek-form">
                                  <div className="custome-checkbox">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="checkbox"
                                      id="exampleCheckbox12"
                                      checked={billingAddressCheckbox}
                                      disabled={true}
                                      onChange={(e) =>
                                        billingAddressToggle(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleCheckbox12"
                                    >
                                      <span>
                                        Billing address is same as Shipping
                                        address
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Billing Country{" "}
                                  <span className="required">*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="country_id"
                                  disabled={true}
                                  value={details?.country_id}
                                  // onChange={(e) => handleDetailsChange(e)}
                                >
                                  <option value="">
                                    Select Billing Country *
                                  </option>
                                  {country.map((country, index) => {
                                    return (
                                      <option key={index} value={country.id}>
                                        {" "}
                                        {country?.country}{" "}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Billing State{" "}
                                  <span className="required">*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="billing_state"
                                  value={details?.billing_state}
                                  disabled={true}
                                  onChange={(e) => handleDetailsChange(e)}
                                >
                                  <option value="">
                                    Select Billing State *{" "}
                                  </option>
                                  {states.map((state, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={state.id}
                                        // disabled={true}
                                      >
                                        {" "}
                                        {state.state}{" "}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Billing City{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  type="text"
                                  required=""
                                  name="billing_city"
                                  className="form-control"
                                  value={details.billing_city}
                                  disabled={true}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Billing city*"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Billing Address{" "}
                                  <span className="required">*</span>
                                </label>
                                <textarea
                                  required=""
                                  name="billing_address"
                                  style={{ height: "64px" }}
                                  rows={2}
                                  disabled={true}
                                  className="form-control"
                                  value={details.billing_address}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Billing Address*"
                                ></textarea>
                              </div>
                              {/* <div className="form-group col-md-6">
                                <label>
                                  Billing Street{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  name="billing_street"
                                  className="form-control"
                                  value={details.billing_street}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Billing Street *"
                                />
                              </div> */}
                              <div className="form-group col-md-6">
                                <label>
                                  Billing Zip{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  type="number"
                                  required=""
                                  name="billing_zip"
                                  className="form-control"
                                  disabled={true}
                                  value={details.billing_zip}
                                  onChange={(e) => handleDetailsChange(e)}
                                  placeholder="Billing Zip *"
                                />
                              </div>

                              {/* <div className="form-group col-md-6">
                                <label>
                                  Ship Via
                                </label>
                                <select name="ship_via" id="ship_via" value={details.ship_via} onChange={(e) => handleDetailsChange(e)} className="form-control">
                                  <option value="0">Select Ship Via</option>
                                  {
                                      shipVia.map((shipVia,index) =>{
                                          return (
                                              <option key={index} value={shipVia.id}> {shipVia.ship_type}</option>
                                          );
                                      })
                                  }
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Payment Type <span className="required">*</span>
                                </label>
                                <select name="payment_type" id="payment_type" value={details.payment_type} onChange={(e) => handleDetailsChange(e)} className="form-control">
                                  <option value="">Select Payment Type</option>
                                      {
                                          paymentType.map((payment,index) =>{
                                              return (
                                                  <option key={index} value={payment.id}> {payment.payment_type}</option>
                                              );
                                          })
                                      }
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Membership Type <span className="required">*</span>
                                </label>
                                <select name="cc_tax_id" id="cc_tax_id" value={details.cc_tax_id} onChange={(e) => handleDetailsChange(e)} className="form-control">
                                <option value="">Select Membership Type *</option>
                                    {
                                        customerCategory.map((cc,index) =>{
                                            return (
                                                <option key={index} value={cc.cc_id}> {cc.cc_name}</option>
                                            );
                                        })
                                    }
                                </select>
                              </div> */}
                              <h5>Password change</h5>
                              <span>(leave blank to leave unchanged)</span>
                              <div className="form-group col-md-6">
                                <label>
                                  Current Password{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="password_current"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={
                                    details.password_current
                                      ? details.password_current
                                      : ""
                                  }
                                  type={passwordType ? "text" : "password"}
                                />
                                <img
                                  onClick={() => setpasswordType(!passwordType)}
                                  src={
                                    "/images/theme/icons/" +
                                    (passwordType
                                      ? "icon-eye.svg"
                                      : "icon-eye-off.svg")
                                  }
                                  className="show-password"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  New Password{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="new_password"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.new_password}
                                  type={passwordType ? "text" : "password"}
                                />
                                <img
                                  onClick={() => setpasswordType(!passwordType)}
                                  src={
                                    "/images/theme/icons/" +
                                    (passwordType
                                      ? "icon-eye.svg"
                                      : "icon-eye-off.svg")
                                  }
                                  className="show-password"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Confirm Password{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="new_password_confirmation"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.new_password_confirmation}
                                  type={passwordType ? "text" : "password"}
                                />
                                <img
                                  onClick={() => setpasswordType(!passwordType)}
                                  src={
                                    "/images/theme/icons/" +
                                    (passwordType
                                      ? "icon-eye.svg"
                                      : "icon-eye-off.svg")
                                  }
                                  className="show-password"
                                />
                              </div>
                              <div className="col-md-12">
                                <button
                                  type="button"
                                  className="btn btn-fill-out submit font-weight-bold"
                                  name="submit"
                                  value="Submit"
                                  onClick={() => handleSubmit()}
                                >
                                  Save Change
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AccountDetails;
