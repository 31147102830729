import { Link } from "react-router-dom";
import Loader from "../Loader";

const Banner = ({ image1, image2, image3, isLoading }) => {
  let ele = document?.getElementById("noright_click");
  ele?.addEventListener("contextmenu", (ev) => {
    ev?.preventDefault(); // this will prevent browser default behavior
  });

  return (
    <section className="banners mb-25">
      <div className="container">
        {!isLoading ? (
          <div className="row" id="noright_click">
            <div className="col-lg-4 col-md-6">
              <div className="banner-img">
                {image1 ? (
                  <img
                    height={"222px"}
                    width={"429px"}
                    // src="/images/banner/secondRow3.jpg"
                    src={process.env.REACT_APP_BASE_URL + "/storage/" + image1}
                    alt=""
                  />
                ) : (
                  <img
                    height={"222px"}
                    width={"429px"}
                    src="/images/banner/secondRow3.jpg"
                    // src={process.env.REACT_APP_BASE_URL + "/storage/" + image1}
                    alt=""
                  />
                )}
                <div className="banner-text">
                  <h4>
                    {/* Everyday Fresh &amp; <br />
                    Clean with Our
                    <br />
                    Products */}
                  </h4>
                  <Link
                    to="/product-category/silicon-pipe"
                    className="btn btn-xs"
                  >
                    Shop Now <i className="fi-rs-arrow-small-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="banner-img">
                {image2 ? (
                  <img
                    height={"222px"}
                    width={"429px"}
                    // src="/images/banner/secondRow1.jpg"
                    src={process.env.REACT_APP_BASE_URL + "/storage/" + image2}
                    alt=""
                  />
                ) : (
                  <img
                    height={"222px"}
                    width={"429px"}
                    src="/images/banner/secondRow1.jpg"
                    // src={process.env.REACT_APP_BASE_URL + "/storage/" + image2}
                    alt=""
                  />
                )}
                <div className="banner-text">
                  <h4>
                    {/* Make your Breakfast
                    <br />
                    Healthy and Easy */}
                  </h4>
                  <Link
                    to="/product-category/cigarette-case"
                    className="btn btn-xs"
                  >
                    Shop Now <i className="fi-rs-arrow-small-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="banner-img mb-sm-0">
                {image3 ? (
                  <img
                    height={"222px"}
                    width={"429px"}
                    // src="/images/banner/secondRow2.jpg"
                    src={process.env.REACT_APP_BASE_URL + "/storage/" + image3}
                    alt=""
                  />
                ) : (
                  <img
                    height={"222px"}
                    width={"429px"}
                    src="/images/banner/secondRow2.jpg"
                    // src={process.env.REACT_APP_BASE_URL + "/storage/" + image3}
                    alt=""
                  />
                )}
                <div className="banner-text">
                  <h4>
                    {/* The best Organic <br />
                    Products Online */}
                  </h4>
                  <Link
                    to={"/product-category/glass-pipe"}
                    className="btn btn-xs"
                  >
                    Shop Now <i className="fi-rs-arrow-small-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : // <Loader />
        null}
      </div>
    </section>
  );
};

export default Banner;
