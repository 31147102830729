import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAccount, verifyLinkAPI } from "../../services/AuthService";

const CreateAccount = () => {
  const [securityCode, setSecurityCode] = useState("");
  const [passwordType, setpasswordType] = useState(false);
  const [confirmPasswordType, setconfirmPasswordType] = useState("");
  const [invalidToggle, setinvalidToggle] = useState(false);
  const [id, setid] = useState("");

  const [state, setState] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyLink();
  }, []);

  const verifyLink = async () => {
    await verifyLinkAPI(params?.token)
      .then((response) => {
        // toast("Created Account Successfully", { type: "success" });
        // navigate("/");
        setid(response?.data?.data?.id);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setinvalidToggle(true);
        if (error?.response?.status === 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status === 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      await createAccount({ ...state, id })
        .then((response) => {
          toast("Created Account Successfully", { type: "success" });
          navigate("/");
        })
        .catch((error) => {
          if (error?.response?.status === 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status === 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    const usernameRegex = /^[a-zA-Z0-9]*$/;

    if (state.username == "") {
      toast("Please enter username.", {
        type: "error",
      });
      return false;
    } else if (!usernameRegex.test(state.username)) {
      toast(
        "Username should not be contain any speacial character or any space.",
        {
          type: "error",
        }
      );
      return false;
    } else if (
      state?.username?.trim()?.length < 6 ||
      state?.username?.trim()?.length > 12
    ) {
      toast("Username Must be between 6 to 12 characters long.", {
        type: "error",
      });
      return false;
    }

    if (state.password === "") {
      toast("Please enter password.", {
        type: "error",
      });
      return false;
    }

    if (state.confirmPassword === "") {
      toast("Please enter confirm password.", {
        type: "error",
      });
      return false;
    }

    if (state.confirmPassword !== state.password) {
      toast("Password and Confirm Password should be the same.", {
        type: "error",
      });
      return false;
    }

    return true;
  };

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a to="/">
              <i className="fi-rs-home mr-5" />
              Home
            </a>
            <span /> Create Account
          </div>
        </div>
      </div>
      <div className="page-content login-page pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <div className="row">
                <div className="col-lg-6 pr-30 d-none d-lg-block">
                  {/* <img
                                        className="border-radius-15"
                                        src="assets/imgs/page/login-1.png"
                                        alt=""
                                    /> */}
                  {!invalidToggle && (
                    <video
                      width="100%"
                      loading="lazy"
                      muted="muted"
                      src="/images/login-illustartion.mp4"
                      type="video/mp4"
                      autoPlay="autoplay"
                      loop="loop"
                    ></video>
                  )}
                </div>
                {!invalidToggle ? (
                  <div className="col-lg-6 col-md-8">
                    <div className="login_wrap widget-taber-content background-white">
                      <div className="padding_eight_all bg-white">
                        <div className="heading_s1">
                          <h1 className="mb-30">Create Account</h1>

                          {/* <p className="mb-30 login-hover">
                          Don't have an account?{" "}
                          <Link to="/register">Create here</Link>
                        </p> */}
                        </div>
                        <form method="post">
                          <div className="form-group">
                            <input
                              type="text"
                              required=""
                              name="username"
                              placeholder="Username *"
                              value={state.username}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              required=""
                              type={passwordType ? "text" : "password"}
                              name="password"
                              placeholder="Your password *"
                              value={state.password}
                              autoComplete="true"
                              onChange={(e) => handleInputChange(e)}
                            />
                            <img
                              onClick={() => setpasswordType(!passwordType)}
                              src={
                                "/images/theme/icons/" +
                                (passwordType
                                  ? "icon-eye.svg"
                                  : "icon-eye-off.svg")
                              }
                              className="show-password"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              required=""
                              type={passwordType ? "text" : "password"}
                              name="confirmPassword"
                              placeholder="Confirm Password *"
                              value={state.confirmPassword}
                              autoComplete="true"
                              onChange={(e) => handleInputChange(e)}
                            />
                            <img
                              onClick={() => setpasswordType(!passwordType)}
                              src={
                                "/images/theme/icons/" +
                                (passwordType
                                  ? "icon-eye.svg"
                                  : "icon-eye-off.svg")
                              }
                              className="show-password"
                            />
                          </div>

                          <div className="login_footer form-group mb-20">
                            {/* <div className="chek-form">
                                                        <div className="custome-checkbox">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="checkbox"
                                                                id="exampleCheckbox1"
                                                                defaultValue=""
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="exampleCheckbox1"
                                                            >
                                                                <span>Remember me</span>
                                                            </label>
                                                        </div>
                                                    </div> */}
                            {/* <Link
                            className="text-muted"
                            to="/my-account/lost-password"
                          >
                            Forgot password?
                          </Link> */}
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-heading btn-block hover-up"
                              name="login"
                              onClick={(e) => handleSubmit(e)}
                            >
                              Create
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <h1 className="text-center">This link has been expired</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CreateAccount;
