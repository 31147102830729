import { useContext, useRef } from "react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { retriveCategories } from "../../services/CategoryService";
import { AppContext } from "../../utils/AppProvider";
import { toast } from "react-toastify";
import { logout } from "../../services/AuthService";
import { countNotification } from "../../services/NotificationService";
import Notification from "../Notification/Notification";
import ConfirmationPopupModal from "../ConfirmationPopupModal";
import { decodeHTMLEntities } from "../../utils/constant";
import { retriveItemByIds, wishlistCount } from "../../services/ItemService";
import _, { isEmpty } from "lodash";
import Loader from "../Loader";

const HeaderBottom = ({ signOutClicked }) => {
  const [showAllCategory, setShowAllCategory] = useState(false);
  const [showAllCat, setShowAllCat] = useState(true);
  const [showMoreEnable, setshowMoreEnable] = useState(true);
  const [signOutPopup, setsignOutPopup] = useState(false);
  const [categories, setcategories] = useState([]);
  const [parentCatId, setParentCatId] = useState("");
  const [catrgorySearch, setcatrgorySearch] = useState("");
  const location = useLocation();
  const [isLoading, setisLoading] = useState(true);
  const [showNotifications, setShowNotifications] = useState(false);
  const {
    cart_qty,
    setIsAuthenticated,
    is_authenticated,
    setShowMobileSearch,
    setSearch,
    wishlist_qty,
    setWishlistQty,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [mobilemenu, SetMobileMenu] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const params = useParams();
  let adjustment_balance = localStorage.getItem("adjustment_balance");
  let opening_balance = localStorage.getItem("opening_balance");

  let heightOfScreen = window.innerHeight - 200;


  useEffect(async () => {
    if (is_authenticated == 1) {
      // getCategories();
      await countNotification()
        .then((response) => {
          setNotificationCount(response.data.data);
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  }, [is_authenticated]);

  useEffect(async () => {
    await wishlistCount()
      .then((response) => {
        setWishlistQty(response?.data?.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);

  useEffect(async () => {
    if (is_authenticated == 1) {
      setcategories([]);
      setisLoading(true);
      getCategories();
    }
  }, [catrgorySearch]);

  useEffect(async () => {
    setShowAllCategory(false);
    setcatrgorySearch("");
    SetMobileMenu(false);

    // setshowMoreEnable(false);
    // setShowAllCat(false);
    setParentCatId(null);
  }, [location]);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setShowAllCategory(false);
        if (!mobilemenu) {
          setcatrgorySearch("");
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  const getCategories = async () => {
    await retriveCategories(catrgorySearch)
      .then((response) => {
        if (response.data.data) {
          setcategories(response.data.data);
          setisLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
      });
  };
  const toggleCategoryDropDown = (e) => {
    e.preventDefault();
    setShowAllCategory(!showAllCategory);

    setParentCatId(null);
  };
  const mobiledropdown = (index) => {
    document
      .getElementById(`sub-menu-${index}`)
      .classList.toggle("show-dropdown");
  };
  const handleLogout = async (e) => {
    e.preventDefault();
    await logout()
      .then(() => {
        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("opening_balance");
        localStorage.removeItem("adjustment_balance");

        setIsAuthenticated(0);
        setsignOutPopup(false);
        navigate("/login");
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setShowMobileSearch(false);
    navigate(`/products`);
  };

  const handleCategory = (e) => {
    e?.preventDefault();
    setcategories([]);
    setisLoading(true);
    getCategories();
  };

  const openNotificationModal = () => {
    setShowNotifications(true);
  };

  const handleNotificationCount = async (id, index) => {
    setNotificationCount(notificationCount - 1);
  };

  return (
    <div className="header-bottom header-bottom-bg-color sticky-bar">
      <div className="container">
        <div className="header-wrap header-space-between position-relative">
          <div
            className="header-action-icon-2 d-block d-lg-none"
            onClick={() => SetMobileMenu(!mobilemenu)}
          >
            <div className="burger-icon burger-icon-white">
              <span className="burger-icon-top" />
              <span className="burger-icon-mid" />
              <span className="burger-icon-bottom" />
            </div>
          </div>
          <div className="logo logo-width-1 d-block d-lg-none">
            <Link to="/">
              <img src="/images/sl_logo.png" alt="logo" />
            </Link>
          </div>
          <div
            className="header-nav d-none d-lg-flex"
            id="navbar-bottom-categories"
            style={{ justifyContent: "flex-start" }}
          >
            <div className="main-categori-wrap d-none d-lg-block" ref={ref}>
              <a
                className={
                  "categories-button-active button-hover"
                  //+ (showAllCategory ? " open" : "")
                }
                // ref={ref}
                onClick={(e) => toggleCategoryDropDown(e)}
              >
                <span className="fi-rs-apps" />
                {/* <span className="et"></span>{" "} */}
                Browse All Categories
                <i className="fi-rs-angle-down" />
              </a>
              {is_authenticated == 1 ? (
                <div
                  className={
                    "categories-dropdown-wrap categories-dropdown-active-large font-heading" +
                    (showAllCategory ? " open" : "") +
                    (showAllCat ? " more-cat-box" : "")
                  }
                  style={{
                    marginTop: "0px",
                    overflow: "hidden",
                    height: `${heightOfScreen}px`,
                  }}
                >
                  <div
                    className="search-style-2"
                    style={{ backgroundImage: "none" }}
                  >
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        handleCategory(e);
                      }}
                      style={{
                        maxWidth: "190px",
                        marginBottom: "15px",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        value={catrgorySearch}
                        onChange={(e) => setcatrgorySearch(e.target.value)}
                        type="text"
                        // ref={ref}
                        placeholder="Search..."
                      />
                      <button
                        type="submit "
                        onClick={(e) => {
                          handleCategory(e);
                        }}
                      >
                        <i
                          className="fi-rs-search "
                          style={{
                            color: "white",
                            borderRadius: "none",
                            marginRight: "3px",
                          }}
                        ></i>
                      </button>
                    </form>
                  </div>
                  <div
                    className="d-flex categori-dropdown-inner"
                    style={{
                      minWidth:
                        parentCatId && showAllCat ? "700px" : "max-content",
                      flexDirection: "column",
                      // height: showMoreEnable ? "350px" : "320px",
                      height: `${heightOfScreen - 130}px`,
                      overflowY: "scroll",
                    }}
                  >
                    {!isLoading ? (
                      <ul>
                        {!_.isEmpty(categories) ? (
                          <>
                            {categories.map((category, index) => {
                              if (showAllCat) {
                                return (
                                  <div key={index}>
                                    <li
                                      onMouseEnter={() =>
                                        setParentCatId(category.id)
                                      }
                                      className="parent-cat"
                                      key={index}
                                      style={{ width: "200px" }}
                                    >
                                      <Link
                                        style={{ width: "100%" }}
                                        onClick={() => SetMobileMenu(false)}
                                        to={
                                          "/product-category/" + category.slug
                                        }
                                      >
                                        {" "}
                                        <img
                                          src="/images/theme/icons/category-6.svg"
                                          alt=""
                                        />
                                        <div className="subcat-icon-hover">
                                          {decodeHTMLEntities(
                                            category.category_name
                                          )}

                                          {categories.filter(
                                            (el) => el.parent_id == category.id
                                          ).length > 0 ? (
                                            <i
                                              className="subcat-icon fi-rs-angle-right"
                                              style={{ alignSelf: "center" }}
                                            ></i>
                                          ) : null}
                                        </div>
                                      </Link>
                                    </li>
                                    {categories.filter(
                                      (el) => el.parent_id == category.id
                                    ).length > 0 &&
                                    parentCatId == category.id ? (
                                      <ul
                                        style={{
                                          left:
                                            parentCatId && showAllCat
                                              ? "35%"
                                              : "93%",
                                          top:
                                            parentCatId && showAllCat
                                              ? "6%"
                                              : "0",
                                          minWidth:
                                            parentCatId && showAllCat
                                              ? "470px"
                                              : "max-content",

                                          // height: "460px",
                                          height: `${heightOfScreen - 100}px`,

                                          overflowY: "scroll",
                                          width: "200px",
                                        }}
                                        className={
                                          "sub-menu show_cat parent-cat"
                                        }
                                      >
                                        {categories.map((sub_cat, _index) => {
                                          if (
                                            sub_cat.parent_id == category.id &&
                                            parentCatId == category.id
                                          ) {
                                            return (
                                              <li
                                                key={"subcat-" + _index}
                                                className="sub-cat"
                                              >
                                                <Link
                                                  to={
                                                    "/product-category/" +
                                                    category.slug +
                                                    "/" +
                                                    sub_cat.slug
                                                  }
                                                  style={{ width: "100%" }}
                                                >
                                                  <img
                                                    src="/images/theme/icons/category-6.svg"
                                                    alt=""
                                                  />
                                                  {decodeHTMLEntities(
                                                    sub_cat.category_name
                                                  )}
                                                </Link>
                                              </li>
                                            );
                                          }
                                        })}
                                      </ul>
                                    ) : null}
                                  </div>
                                );
                              } else {
                                if (showMoreEnable) {
                                  return (
                                    <div key={index}>
                                      <li
                                        onMouseEnter={() =>
                                          setParentCatId(category.id)
                                        }
                                        className="parent-cat"
                                        style={{ width: "200px" }}
                                        key={index}
                                      >
                                        <Link
                                          style={{ width: "100%" }}
                                          to={
                                            "/product-category/" + category.slug
                                          }
                                        >
                                          {" "}
                                          <img
                                            src="/images/theme/icons/category-6.svg"
                                            alt=""
                                          />
                                          <div className="subcat-icon-hover">
                                            {decodeHTMLEntities(
                                              category?.category_name
                                            )}

                                            {categories.filter(
                                              (el) =>
                                                el.parent_id == category.id
                                            ).length > 0 ? (
                                              <i
                                                className="subcat-icon fi-rs-angle-right"
                                                style={{ alignSelf: "center" }}
                                              ></i>
                                            ) : null}
                                          </div>
                                        </Link>
                                      </li>
                                      {categories.filter(
                                        (el) => el.parent_id == category.id
                                      ).length > 0 &&
                                      parentCatId == category.id ? (
                                        <ul className={"sub-menu show_cat"}>
                                          {categories.map((sub_cat, _index) => {
                                            if (
                                              sub_cat.parent_id ==
                                                category.id &&
                                              parentCatId == category.id
                                            ) {
                                              return (
                                                <div>
                                                  <li
                                                    key={"subcat-" + _index}
                                                    className="sub-cat"
                                                  >
                                                    <Link
                                                      to={
                                                        "/product-category/" +
                                                        category.slug +
                                                        "/" +
                                                        sub_cat.slug
                                                      }
                                                    >
                                                      <img
                                                        src="/images/theme/icons/category-6.svg"
                                                        alt=""
                                                      />
                                                      {decodeHTMLEntities(
                                                        sub_cat?.category_name
                                                      )}
                                                    </Link>
                                                  </li>
                                                </div>
                                              );
                                            }
                                          })}
                                        </ul>
                                      ) : null}
                                    </div>
                                  );
                                } else {
                                  if (index <= 4) {
                                    return (
                                      <div key={index}>
                                        <li
                                          onMouseEnter={() =>
                                            setParentCatId(category.id)
                                          }
                                          className="parent-cat"
                                          key={index}
                                        >
                                          <Link
                                            style={{ width: "100%" }}
                                            to={
                                              "/product-category/" +
                                              category.slug
                                            }
                                          >
                                            {" "}
                                            <img
                                              src="/images/theme/icons/category-6.svg"
                                              alt=""
                                            />
                                            <div className="subcat-icon-hover">
                                              {decodeHTMLEntities(
                                                category.category_name
                                              )}
                                              {categories.filter(
                                                (el) =>
                                                  el.parent_id == category.id
                                              ).length > 0 ? (
                                                <i
                                                  className="subcat-icon fi-rs-angle-right"
                                                  style={{
                                                    alignSelf: "center",
                                                  }}
                                                ></i>
                                              ) : null}
                                            </div>
                                          </Link>
                                        </li>
                                        {categories.filter(
                                          (el) => el.parent_id == category.id
                                        ).length > 0 &&
                                        parentCatId == category.id ? (
                                          <ul className={"sub-menu show_cat"}>
                                            {categories.map(
                                              (sub_cat, _index) => {
                                                if (
                                                  sub_cat.parent_id ==
                                                    category.id &&
                                                  parentCatId == category.id
                                                ) {
                                                  return (
                                                    <div>
                                                      <li
                                                        key={"subcat-" + _index}
                                                        className="sub-cat"
                                                      >
                                                        <Link
                                                          to={
                                                            "/product-category/" +
                                                            category.slug +
                                                            "/" +
                                                            sub_cat.slug
                                                          }
                                                        >
                                                          <img
                                                            src="/images/theme/icons/category-6.svg"
                                                            alt=""
                                                          />
                                                          {decodeHTMLEntities(
                                                            sub_cat.category_name
                                                          )}
                                                        </Link>
                                                      </li>
                                                    </div>
                                                  );
                                                }
                                              }
                                            )}
                                          </ul>
                                        ) : null}
                                      </div>
                                    );
                                  }
                                }
                              }
                            })}
                          </>
                        ) : (
                          <div
                            style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            <span>No Data found</span>
                          </div>
                        )}
                      </ul>
                    ) : (
                      <Loader />
                    )}
                    {/*   <ul className="end">
                                    {
                                        categories.map((category, index) => {
                                            if(showAllCat && index > categories.length /2){
                                                return (
                                                    <li key={index}>
                                                    <Link to={"/product-category/" + (category.slug)}>
                                                        {" "}
                                                        <img src="/images/theme/icons/category-6.svg" alt="" />
                                                        { category.category_name }
                                                    </Link>
                                                </li>
                                            );
                                                }else{
                                                    if(!showAllCat && index > 4 && index <= 9){
                                                     return (
                                                        <li key={index}>
                                                    <Link to={"/product-category/" + (category.slug)}>
                                                        {" "}
                                                        <img src="/images/theme/icons/category-6.svg" alt="" />
                                                        { category.category_name }
                                                    </Link>
                                                </li>
                                                     );   
                                                    }
                                                }
                                        })
                                       }
                                        
                                    </ul> */}
                  </div>

                  {/* <div
                    // onClick={() => setShowAllCat(!showAllCat)}
                    onClick={() => setshowMoreEnable(!showMoreEnable)}
                    className={"more_categories" + (showAllCat ? " show" : "")}
                  >
                    <span className="icon" />{" "}
                    <span className="heading-sm-1">
                      {" "}
                      {showMoreEnable ? "See less..." : "Show more..."}
                    </span>
                  </div> */}
                </div>
              ) : (
                <div
                  className={
                    "categories-dropdown-wrap categories-dropdown-active-large font-heading" +
                    (showAllCategory ? " open" : "") +
                    (showAllCat ? " more-cat-box" : "")
                  }
                  style={{ height: `${heightOfScreen}px` }}
                >
                  <div className={"more_categories"}>
                    <Link to="/register"> Please Sign Up First</Link>
                  </div>
                </div>
              )}
            </div>
            <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
              <nav>
                <ul>
                  <li>
                    <Link to="/"> Home </Link>
                  </li>

                  {/* {is_authenticated == 1 && (
                    <>
                      <li>
                        <Link to="/product-category/edibles">Edibles</Link>
                      </li>

                      <li>
                        <Link to="/product-category/flower-pre-rolls">
                          {" "}
                          Flower & Pre-Rolls
                        </Link>
                      </li>

                      <li>
                        <Link to="/product-category/tinctures">Tinctures</Link>
                      </li>

                      <li>
                        <Link to="/product-category/vape">Vape</Link>
                      </li>
                    </>
                  )} */}

                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>

                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="hotline d-none d-lg-flex">
            <img src="/images/theme/icons/icon-headphone.svg" alt="hotline" />
            <p>
              (630) 785-6838<span>24/7 Support Center</span>
            </p>
          </div>
          <div
            className={
              "mobile-header-active mobile-header-wrapper-style d-xl-none sidebar-" +
              (mobilemenu ? "visible" : "")
            }
          >
            <div className="mobile-header-wrapper-inner">
              <div className="mobile-header-top">
                <div className="mobile-header-logo">
                  <Link to="/">
                    <img
                      src="/images/sl_logo.png"
                      alt="logo"
                      onClick={() => SetMobileMenu(!mobilemenu)}
                    />
                  </Link>
                </div>
                <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                  <button
                    className="close-style search-close"
                    onClick={() => SetMobileMenu(!mobilemenu)}
                  >
                    <i className="icon-top"></i>
                    <i className="icon-bottom"></i>
                  </button>
                </div>
              </div>
              <div className="mobile-header-content-area">
                <div className="mobile-search search-style-3 mobile-header-border">
                  <form onSubmit={(e) => handleSearch(e)}>
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      placeholder="Search for products..."
                      autoComplete="off"
                      className="mobile-menu-search"
                    />
                    <button>
                      <i
                        className="fi-rs-search"
                        onClick={(e) => {
                          e?.preventDefault();
                          SetMobileMenu(!mobilemenu);
                        }}
                      ></i>
                    </button>
                  </form>
                </div>
                <div className="mobile-menu-wrap mobile-header-border">
                  {/*  MENU menu start */}
                  <nav>
                    <ul className="mobile-menu font-heading">
                      <h4 className="mobile-menu">Menu</h4>
                      <li className="menu-item-has-children">
                        <span className="menu-expand"></span>
                        <Link to="/" onClick={() => SetMobileMenu(!mobilemenu)}>
                          {" "}
                          Home{" "}
                        </Link>
                      </li>
                      {is_authenticated == 1 && (
                        <>
                          <li className="menu-item-has-children">
                            <span className="menu-expand"></span>
                            <Link
                              to="/product-category/edibles"
                              onClick={() => SetMobileMenu(!mobilemenu)}
                            >
                              Edibles
                            </Link>
                          </li>
                          <li className="menu-item-has-children">
                            <span className="menu-expand"></span>
                            <Link
                              to="/product-category/flower-pre-rolls"
                              onClick={() => SetMobileMenu(!mobilemenu)}
                            >
                              {" "}
                              Flower & Pre-Rolls
                            </Link>
                          </li>
                          <li className="menu-item-has-children">
                            <span className="menu-expand"></span>
                            <Link
                              to="/product-category/tinctures"
                              onClick={() => SetMobileMenu(!mobilemenu)}
                            >
                              Tinctures
                            </Link>
                          </li>
                          <li className="menu-item-has-children">
                            <span className="menu-expand"></span>
                            <Link
                              to="/product-category/vape"
                              onClick={() => SetMobileMenu(!mobilemenu)}
                            >
                              Vape
                            </Link>
                          </li>
                        </>
                      )}

                      <li className="menu-item-has-children">
                        <span className="menu-expand"></span>
                        <Link
                          to="/about-us"
                          onClick={() => SetMobileMenu(!mobilemenu)}
                        >
                          About Us
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <span className="menu-expand"></span>
                        <Link
                          to="/contact"
                          onClick={() => SetMobileMenu(!mobilemenu)}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </nav>
                  <nav>
                    <ul
                      className="mobile-menu font-heading"
                      ref={mobilemenu ? ref : null}
                    >
                      <h4 className="mobile-menu">Categories</h4>
                      <div className="mt-4 mobile-search search-style-3 mobile-header-border">
                        <form
                          onSubmit={(e) => {
                            handleCategory(e);
                          }}
                        >
                          <input
                            onChange={(e) => setcatrgorySearch(e.target.value)}
                            type="text"
                            placeholder="Search for Category..."
                            autoComplete="off"
                            value={catrgorySearch}
                            className="mobile-menu-search"
                          />
                          <button>
                            <i
                              className="fi-rs-search"
                              onClick={(e) => {
                                handleCategory(e);
                              }}
                            ></i>
                          </button>
                        </form>
                      </div>
                      <div style={{ height: "400px", overflow: "scroll" }}>
                        {categories?.map((category, index) => {
                          if (showAllCat) {
                            return (
                              <li
                                className="menu-item-has-children"
                                key={index}
                              >
                                {categories.filter(
                                  (el) => el.parent_id == category.id
                                ).length > 0 ? (
                                  <span
                                    className="menu-expand icon-mobile-menu"
                                    onClick={(e) => mobiledropdown(index)}
                                  >
                                    <i className="fi-rs-angle-small-down"></i>
                                  </span>
                                ) : null}
                                <Link
                                  style={{ width: "100%" }}
                                  to={"/product-category/" + category.slug}
                                  onClick={() => {
                                    // SetMobileMenu(false);
                                    // setcatrgorySearch("");
                                  }}
                                >
                                  {decodeHTMLEntities(category.category_name)}
                                </Link>
                                {categories.filter(
                                  (el) => el.parent_id == category.id
                                ).length > 0 ? (
                                  <ul
                                    className="dropdown show-dropdown"
                                    id={"sub-menu-" + index}
                                  >
                                    {categories.map((sub_cat, _index) => {
                                      if (sub_cat.parent_id == category.id) {
                                        return (
                                          <li key={"subcat-" + _index}>
                                            <Link
                                              to={
                                                "/product-category/" +
                                                category.slug +
                                                "/" +
                                                sub_cat.slug
                                              }
                                              onClick={() =>
                                                SetMobileMenu(false)
                                              }
                                            >
                                              {decodeHTMLEntities(
                                                sub_cat.category_name
                                              )}
                                            </Link>
                                          </li>
                                        );
                                      }
                                    })}
                                  </ul>
                                ) : null}
                              </li>
                            );
                          } else {
                            if (index <= 4) {
                              return (
                                <li
                                  className="menu-item-has-children"
                                  key={index}
                                >
                                  {categories.filter(
                                    (el) => el.parent_id == category.id
                                  ).length > 0 ? (
                                    <span
                                      className="menu-expand icon-mobile-menu"
                                      onClick={(e) => mobiledropdown(index)}
                                    >
                                      <i className="fi-rs-angle-small-down"></i>
                                    </span>
                                  ) : null}
                                  <Link
                                    style={{ width: "100%" }}
                                    to={"/product-category/" + category.slug}
                                    onClick={() => SetMobileMenu(!mobilemenu)}
                                  >
                                    {decodeHTMLEntities(category.category_name)}
                                  </Link>
                                  {categories.filter(
                                    (el) => el.parent_id == category.id
                                  ).length > 0 ? (
                                    <ul
                                      className="dropdown show-dropdown"
                                      id={"sub-menu-" + index}
                                    >
                                      {categories.map((sub_cat, _index) => {
                                        if (sub_cat.parent_id == category.id) {
                                          return (
                                            <li key={"subcat-" + _index}>
                                              <Link
                                                to={
                                                  "/product-category/" +
                                                  category.slug +
                                                  "/" +
                                                  sub_cat.slug
                                                }
                                                onClick={() =>
                                                  SetMobileMenu(!mobilemenu)
                                                }
                                              >
                                                {decodeHTMLEntities(
                                                  sub_cat.category_name
                                                )}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      })}
                                    </ul>
                                  ) : null}
                                </li>
                              );
                            }
                          }
                        })}
                      </div>
                    </ul>
                  </nav>
                  {/* mobile menu end */}
                </div>
                <div
                  // onClick={() => setShowAllCat(!showAllCat)}
                  className={"more_categories" + (showAllCat ? " show" : "")}
                >
                  <span className="icon-mobile-menu">
                    <i
                      className={
                        showAllCat ? "fi-rs-angle-up" : "fi-rs-angle-down"
                      }
                    ></i>
                  </span>
                  <span className="heading-sm-1">
                    {" "}
                    {/* {showAllCat ? "See less..." : "Show more..."} */}
                  </span>
                </div>
                <div className="mobile-header-info-wrap">
                  {is_authenticated ? (
                    <>
                      <div className="single-mobile-header-info">
                        <Link
                          // to="/my-account"
                          style={{ display: "flex" }}
                          // onClick={() => SetMobileMenu(!mobilemenu)}
                        >
                          <i
                            className="fi fi-rs-bank mr-10"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></i>
                          <p>
                            <span>Opening Balance</span>
                            <h3>${opening_balance || 0.0}</h3>
                          </p>
                        </Link>
                      </div>
                      <div className="single-mobile-header-info">
                        <Link
                          to="/my-account"
                          style={{ display: "flex" }}
                          // onClick={() => SetMobileMenu(!mobilemenu)}
                        >
                          <i
                            className="fi fi-rs-bank mr-10"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></i>
                          <p>
                            <span>Available Balance</span>
                            <h3>${adjustment_balance || 0.0}</h3>
                          </p>
                        </Link>
                      </div>
                      <div className="single-mobile-header-info">
                        <Link
                          to="/my-account"
                          onClick={() => SetMobileMenu(!mobilemenu)}
                        >
                          <i className="fi fi-rs-user mr-10" />
                          My Account
                        </Link>
                      </div>
                      <div className="single-mobile-header-info">
                        <a
                          href=""
                          onClick={(e) => {
                            e?.preventDefault();
                            signOutClicked();
                            SetMobileMenu(!mobilemenu);
                          }}
                        >
                          <i className="fi fi-rs-sign-out mr-10" />
                          Sign out
                        </a>
                      </div>
                    </>
                  ) : (
                    <div className="single-mobile-header-info">
                      <Link
                        to="/login"
                        onClick={() => SetMobileMenu(!mobilemenu)}
                        className="btn btn-sm"
                      >
                        Sign In
                      </Link>
                    </div>
                  )}
                  <div className="single-mobile-header-info">
                    <a href="#">
                      <i className="fi-rs-headphones"></i>(630) 785-6838
                    </a>
                  </div>
                </div>
                <div className="mobile-social-icon mb-50">
                  {/* <h6 className="mb-15">Follow Us</h6> */}
                  {/* <a href="" onClick={(e) => e.preventDefault()}>
                                        <img src="/images/theme/icons/icon-facebook-white.svg" alt="" />
                                    </a>
                                    <a href="" onClick={(e) => e.preventDefault()}>
                                        <img src="/images/theme/icons/icon-twitter-white.svg" alt="" />
                                    </a>
                                    <a href="" onClick={(e) => e.preventDefault()}>
                                        <img
                                            src="/images/theme/icons/icon-instagram-white.svg"
                                            alt=""
                                        />
                                    </a>
                                    <a href="" onClick={(e) => e.preventDefault()}>
                                        <img
                                            src="/images/theme/icons/icon-pinterest-white.svg"
                                            alt=""
                                        />
                                    </a>
                                    <a href="" onClick={(e) => e.preventDefault()}>
                                        <img src="/images/theme/icons/icon-youtube-white.svg" alt="" />
                                    </a> */}
                </div>
              </div>
            </div>
          </div>
          {/* end mobile menu */}
          <div className="header-action-right d-block d-lg-none">
            <div className="header-action-2">
              <div
                className="header-action-icon-2"
                onClick={() => openNotificationModal()}
              >
                <Link>
                  <img
                    className="svgInject"
                    alt="Nest"
                    src="/images/theme/icons/notification.svg"
                  />
                  <span className="pro-count blue"> {notificationCount}</span>
                </Link>
              </div>
              <div className="header-action-icon-2">
                <Link to="/wishlist">
                  <img alt="Nest" src="/images/theme/icons/icon-heart.svg" />
                  <span className="pro-count white"> {wishlist_qty} </span>
                </Link>
              </div>
              <div className="header-action-icon-2">
                <Link className="mini-cart-icon" to="/cart">
                  <img alt="Nest" src="/images/theme/icons/icon-cart.svg" />
                  <span className="pro-count white">{cart_qty}</span>
                </Link>

                <div className="cart-dropdown-wrap cart-dropdown-hm2 d-none">
                  <ul>
                    <li>
                      <div className="shopping-cart-img">
                        <a href="#shop-product-right.html">
                          <img alt="Nest" src="/images/shop/thumbnail-3.jpg" />
                        </a>
                      </div>
                      <div className="shopping-cart-title">
                        <h4>
                          <a href="#shop-product-right.html">
                            Plain Striola Shirts
                          </a>
                        </h4>
                        <h3>
                          <span>1 × </span>$800.00
                        </h3>
                      </div>
                      <div className="shopping-cart-delete">
                        <a href="##">
                          <i className="fi-rs-cross-small" />
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="shopping-cart-img">
                        <a href="#shop-product-right.html">
                          <img alt="Nest" src="/images/shop/thumbnail-4.jpg" />
                        </a>
                      </div>
                      <div className="shopping-cart-title">
                        <h4>
                          <a href="#shop-product-right.html">
                            Macbook Pro 2022
                          </a>
                        </h4>
                        <h3>
                          <span>1 × </span>$3500.00
                        </h3>
                      </div>
                      <div className="shopping-cart-delete">
                        <a href="##">
                          <i className="fi-rs-cross-small" />
                        </a>
                      </div>
                    </li>
                  </ul>
                  <div className="shopping-cart-footer">
                    <div className="shopping-cart-total">
                      <h4>
                        Total <span>$383.00</span>
                      </h4>
                    </div>
                    <div className="shopping-cart-button">
                      <a href="#shop-cart.html">View cart</a>
                      <a href="#shop-checkout.html">Checkout</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header-action-icon-2">
                <Link to="/my-account">
                  <img alt="account" src="/images/theme/icons/icon-user.svg" />
                  {/* <span className="pro-count white"> {wishlist_qty} </span> */}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showNotifications == true ? (
        <Notification
          show={() => setShowNotifications(false)}
          count={handleNotificationCount}
        />
      ) : null}
    </div>
  );
};

export default HeaderBottom;
