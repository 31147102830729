import "./App.css";
import "./assets/css/sass/main.scss";
import "./assets/css/main.css";
import "./assets/css/responsive.css";
import "./assets/css/b2b-custom.css";
import "./assets/css/plugins/slider-range.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import Index from "./layout/Index";
import { useEffect, useState } from "react";
import PopupModal from "./components/PopupModal";
import CookiesModal from "./components/CookiesModal";
import { cookiesData, postCookiesData } from "./services/AuthService";
import _ from "lodash";

function App() {
  let approvelOfAdult = localStorage.getItem("olderThanTwentyOne");

  const [yesClickedToggle, setyesClickedToggle] = useState(approvelOfAdult);
  const [cookiesModal, setcookiesModal] = useState(
    localStorage.getItem("cookie_data")
  );

  // const getCookieData = async () => {
  //   await cookiesData()
  //     .then((response) => {
  //       // setcookiesModal(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log("Error", error);
  //     });
  // };

  const postCookiesDataFun = async () => {
    await postCookiesData({ cookie_data: 1 })
      .then((response) => {
        // setcookiesModal(true);
        localStorage.setItem("cookie_data", true);
        setcookiesModal(true);
        toast(response?.data?.message, { type: "success" });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const onClickCookie = () => {
    localStorage.setItem("cookie_data", false);
    setcookiesModal(false);
  };

  const yesClicked = () => {
    setyesClickedToggle(true);
    localStorage.setItem("olderThanTwentyOne", true);
  };

  return (
    <Router>
      <ToastContainer pauseOnFocusLoss={false} autoClose={500} />
      {!yesClickedToggle && <PopupModal yesClicked={yesClicked} />}
      <Index />
      {_.isNil(cookiesModal) && (
        <CookiesModal
          onClick={onClickCookie}
          acceptedClicked={postCookiesDataFun}
        />
      )}
    </Router>
  );
}

export default App;
