import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveOrdersDetails } from "../../services/OrderService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { convertToSlug, decodeHTMLEntities } from "../../utils/constant";
import { userId } from "../../layout/Index";

const OrderDetails = () => {
  const [items, setitems] = useState({});
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalTaxAmount, settotalTaxAmount] = useState(0);
  const [order_status, setorder_status] = useState();

  const params = useParams();
  const navigate = useNavigate();

  let date = "";

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrders();
  }, []);

  const filterByApproved = (data) => {
    let mainArray = [];

    for (let a of data) {
      if (a?.fulfillment_status === "fulfilled") {
        mainArray.push(a);
      }
    }

    return mainArray;
  };

  const getOrders = async () => {
    await retriveOrdersDetails(params?.id, params?.stateId)
      .then((response) => {
        let st = 0;
        let totalTax = 0;
        if (response?.data?.data) {
          // const data = filterByApproved(response.data.data?.line_items);

          setitems(response.data.data?.line_items);
          setorder_status(response.data.data?.order_status);
          response.data.data?.line_items.map((i, index) => {
            st += i.quantity * i.item?.detail.sales_price;
            if (i?.item?.detail?.taxValue?.tax_product_amount) {
              totalTax +=
                i?.quantity * i?.item?.detail?.taxValue?.tax_product_amount;
            }
          });

          setSubtotal(st.toFixed(2));
          settotalTaxAmount(totalTax?.toFixed(2));
          setTotal(Number(st.toFixed(2)) + Number(totalTax?.toFixed(2)));
        }
      })
      .catch((error) => {
        if (error?.response?.status === 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status === 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span />
            <Link to="/my-account/orders" rel="nofollow">
              Orders
            </Link>
            <span />
            Order
          </div>
        </div>
      </div>
      <div className="container mb-80 mt-50">
        <div className="row">
          <div className="col-lg-9 mb-40">
            <h1 className="heading-2 mb-10">Your Order</h1>
            <div className="d-flex justify-content-between">
              {/* <h6 className="text-body">
                There are <span className="text-brand"> {cart_qty} </span>{" "}
                products in your cart
              </h6> */}
              <p style={{ paddingTop: "10px" }}>
                Order #<mark className="order-number">order-{params?.id}</mark>{" "}
                was placed on{" "}
                <mark className="order-date">
                  {
                    (date =
                      new Date(items[0]?.created_at).toLocaleString("default", {
                        month: "long",
                      }) +
                      " " +
                      new Date(items[0]?.created_at).getDate() +
                      " " +
                      new Date(items[0]?.created_at).getFullYear())
                  }
                </mark>{" "}
                and currently status is{" "}
                <mark className="order-status">{order_status}</mark>.
              </p>
              <h6 className="text-body d-none">
                <a href="#" className="text-muted">
                  <i className="fi-rs-trash mr-5 " />
                  Clear Cart
                </a>
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9">
            <div
              className="table-responsive shopping-summery"
              style={{
                height: "670px",
                backgroundColor: "var(--dark-container)",
              }}
            >
              <table className="table table-wishlist">
                <thead
                  style={{
                    top: "0",
                    position: "sticky",
                    zIndex: "1",
                  }}
                >
                  <tr className="main-heading">
                    {/* <th className="custome-checkbox start pl-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="checkbox"
                    id="exampleCheckbox11"
                    defaultValue=""
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exampleCheckbox11"
                  />
                </th> */}
                    <th scope="col" className="text-center" colSpan={2}>
                      Product
                    </th>
                    <th scope="col" className="text-center">
                      {/* Stock Status */}
                    </th>
                    <th scope="col">Unit Price</th>
                    <th scope="col" className="text-center">
                      Quantity
                    </th>
                    <th scope="col">Subtotal</th>
                    <th scope="col" className="text-center">
                      Tax
                    </th>
                    <th scope="col" className="text-center">
                      Grand Total
                    </th>

                    {/* <th scope="col" className="end">
                      Remove
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {items.length > 0 ? (
                    items.map((item, index) => {
                      let titleName = decodeHTMLEntities(item?.item?.item_name);
                      let isDisable = item.item?.detail.stock < 0;
                      let totalAmount = parseFloat(
                        Number(
                          item.item?.detail.sales_price * item.quantity
                        ).toFixed(2)
                      );
                      if (
                        items[index]?.item?.detail?.taxValue?.tax_product_amount
                      ) {
                        totalAmount =
                          totalAmount +
                          parseFloat(
                            Number(
                              items[index]?.item?.detail?.taxValue
                                ?.tax_product_amount * item.quantity
                            ).toFixed(2)
                          );
                      }
                      let ell = document?.getElementById(`disableIdd${index}`);
                      ell?.addEventListener("contextmenu", (ev) => {
                        ev?.preventDefault(); // this will prevent browser default behavior
                      });

                      return (
                        <>
                          <tr
                            key={index}
                            className="d-lg-none d-md-none mobile-w-c pt-30 "
                          >
                            <div
                              className="row"
                              style={{
                                backgroundColor: isDisable && "#A4A9A9",
                              }}
                            >
                              <div className="col-4">
                                <td className="image product-thumbnail pt-40">
                                  <Link
                                    style={{ marginLeft: "5px" }}
                                    to={
                                      "/product/" +
                                      item?.item_id +
                                      "/" +
                                      convertToSlug(item?.item?.item_name) +
                                      "/" +
                                      true
                                    }
                                    title={item.item.item_name}
                                    state={{ isItem: true }}
                                  >
                                    <img
                                      width={90}
                                      height={90}
                                      src={
                                        item?.item?.detail?.item_image
                                          ? process.env.REACT_APP_BASE_URL +
                                            "/storage/products/" +
                                            item?.item?.detail?.item_image
                                          : process.env.REACT_APP_BASE_URL +
                                            "/storage/products/no-image.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </td>
                              </div>
                              <div className="col-8 align-items-center justify-content-center d-flex">
                                <td
                                  className="product-des product-name"
                                  style={{
                                    backgroundColor: isDisable && "#A4A9A9",
                                  }}
                                >
                                  <h6 className="mb-5">
                                    <Link
                                      className="product-name mb-10 text-heading"
                                      to={
                                        "/product/" +
                                        item?.item_id +
                                        "/" +
                                        convertToSlug(item.item.item_name) +
                                        "/" +
                                        true
                                      }
                                      title={titleName}
                                      state={{ isItem: true }}
                                    >
                                      {titleName}
                                    </Link>
                                  </h6>
                                  <div className="product-rate-cover">
                                    <div className="product-rate d-inline-block">
                                      <div
                                        className="product-rating"
                                        style={{ width: "90%" }}
                                      ></div>
                                    </div>
                                    <span className="font-small ml-5 text-muted">
                                      {" "}
                                      (4.0)
                                    </span>
                                    <ul className="">
                                      {item.item?.detail?.brand?.brand_name && (
                                        <li className=" ">
                                          Brand :{" "}
                                          <span className="text-brand mr-3">
                                            {
                                              item.item?.detail?.brand
                                                ?.brand_name
                                            }
                                          </span>
                                        </li>
                                      )}
                                      {item?.item?.detail?.product_code && (
                                        <li>
                                          Product Code :{" "}
                                          <span className="text-brand mr-3">
                                            {" "}
                                            {item?.item?.detail?.product_code}
                                          </span>
                                        </li>
                                      )}
                                      {item.item?.detail?.category
                                        ?.category_name && (
                                        <li className=" ">
                                          Category :{" "}
                                          <span className="text-brand mr-3">
                                            {
                                              item.item?.detail?.category
                                                ?.category_name
                                            }
                                          </span>
                                        </li>
                                      )}

                                      {item?.item?.detail?.color?.title && (
                                        <li className=" ">
                                          Color :{" "}
                                          <span className="text-brand mr-3">
                                            {" "}
                                            {item?.item?.detail?.color?.title}
                                          </span>
                                        </li>
                                      )}
                                      {item?.item?.detail?.size?.title && (
                                        <li>
                                          Size :{" "}
                                          <span className="text-brand mr-3">
                                            {" "}
                                            {item?.item?.detail?.size?.title}
                                          </span>
                                        </li>
                                      )}
                                      {/* <li className="">
                                    Stock :
                                    <span className="in-stock text-brand ml-5">
                                      {" "}
                                      {item?.item?.stock} Items In Stock
                                    </span>
                                  </li> */}
                                      {item?.item?.detail?.flavor?.title && (
                                        <li>
                                          Flavor :{" "}
                                          <span className="text-brand">
                                            {" "}
                                            {item?.item?.detail?.flavor?.title}
                                          </span>
                                        </li>
                                      )}
                                      {item.item?.detail?.unit?.unit_name && (
                                        <li className=" ">
                                          Unit :{" "}
                                          <span className="text-brand mr-3">
                                            {item.item?.detail?.unit?.unit_name}
                                          </span>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                  {isDisable && (
                                    <div
                                      className="alert alert-danger mt-3 "
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: "6px",
                                        marginRight: "5px",
                                      }}
                                      role="alert"
                                    >
                                      <i className="fi-rs-cross-circle ml-3" />
                                      Stock Out!
                                    </div>
                                  )}
                                </td>
                              </div>
                              <div className="col-6">
                                <td
                                  className="price"
                                  style={{
                                    backgroundColor: isDisable && "#A4A9A9",
                                  }}
                                >
                                  <h6 className="text-body">
                                    $
                                    {item?.item?.detail?.sales_price?.toFixed(
                                      2
                                    )}
                                  </h6>
                                  <span className="user-id-font">
                                    <span> {userId}</span>
                                  </span>
                                </td>
                              </div>
                              <div className="col-6">
                                <td
                                  className="price"
                                  style={{
                                    backgroundColor: isDisable && "#A4A9A9",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      gap: "2px",
                                    }}
                                  >
                                    <span>Tax:-</span>
                                    {items[index]?.item?.detail?.taxValue
                                      ?.tax_product_amount ? (
                                      <p className="text-brand ">
                                        $
                                        {(
                                          items[index]?.item?.detail?.taxValue
                                            ?.tax_product_amount *
                                          item?.quantity
                                        ).toFixed(2)}
                                      </p>
                                    ) : (
                                      <p className="text-brand">NA</p>
                                    )}
                                    {items[index]?.item?.detail?.taxValue && (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            {items[index]?.item?.detail
                                              ?.taxValue ? (
                                              <div className="product-rate-cover">
                                                {/* cart-dropdown-hm2 account-dropdown h-50 */}
                                                <ul>
                                                  {items[index]?.item?.detail
                                                    .taxValue?.name && (
                                                    <li className="mr-3">
                                                      <span
                                                        className="text-brand"
                                                        style={{
                                                          minWidth: "65px",
                                                          maxWidth: "80px",
                                                        }}
                                                      >
                                                        Tax Name :
                                                      </span>
                                                      <span>
                                                        {
                                                          items[index]?.item
                                                            ?.detail?.taxValue
                                                            ?.name
                                                        }
                                                      </span>
                                                    </li>
                                                  )}
                                                  {items[index]?.item?.detail
                                                    ?.taxValue?.price_type && (
                                                    <li className="22">
                                                      <span
                                                        className="text-brand"
                                                        style={{
                                                          minWidth: "65px",
                                                          maxWidth: "80px",
                                                        }}
                                                      >
                                                        Tax type :
                                                      </span>
                                                      <span>
                                                        {" "}
                                                        {
                                                          items[index]?.item
                                                            ?.detail?.taxValue
                                                            ?.price_type
                                                        }
                                                      </span>
                                                    </li>
                                                  )}
                                                  {items[index]?.item?.detail
                                                    ?.taxValue
                                                    ?.tax_product_amount && (
                                                    <li className="22">
                                                      <span
                                                        className="text-brand"
                                                        style={{
                                                          minWidth: "65px",
                                                          maxWidth: "80px",
                                                        }}
                                                      >
                                                        Tax Amount :
                                                      </span>
                                                      <span>
                                                        {" "}
                                                        {items[
                                                          index
                                                        ]?.item?.detail?.taxValue?.tax_product_amount?.toFixed(
                                                          2
                                                        )}
                                                      </span>
                                                    </li>
                                                  )}
                                                  {items[index]?.item?.detail
                                                    ?.taxValue?.tax_unit && (
                                                    <li className="mr-3">
                                                      <span
                                                        className="text-brand"
                                                        style={{
                                                          minWidth: "65px",
                                                          maxWidth: "80px",
                                                        }}
                                                      >
                                                        Tax Unit :
                                                      </span>
                                                      <span>
                                                        {" "}
                                                        {
                                                          items[index]?.item
                                                            ?.detail?.taxValue
                                                            ?.tax_unit
                                                        }
                                                      </span>
                                                    </li>
                                                  )}
                                                </ul>
                                              </div>
                                            ) : (
                                              <span>NA</span>
                                            )}
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          className="fi-rs-info "
                                          style={{ paddingTop: "5px" }}
                                        />
                                      </OverlayTrigger>
                                    )}
                                  </div>
                                </td>
                              </div>
                              <div className="col-5">
                                <td
                                  className="text-center detail-info"
                                  data-title="Stock"
                                  style={{
                                    backgroundColor: isDisable && "#A4A9A9",
                                  }}
                                >
                                  <div
                                    className="detail-extralink"
                                    style={{ marginRight: "-20px" }}
                                  >
                                    <div className="detail-qty border radius">
                                      {/* <a href="" className="qty-down">
                                        <i className="fi-rs-angle-small-down" />
                                      </a> */}
                                      {/* <span className="qty-val">
                                        {item?.quantity}
                                      </span> */}
                                      <input
                                        className="qty-val"
                                        value={item?.quantity}
                                        type="number"
                                        min={0}
                                        disabled={true}
                                      />
                                      {/* <a href="" className="qty-up">
                                        <i className="fi-rs-angle-small-up" />
                                      </a> */}
                                    </div>
                                  </div>
                                </td>
                              </div>
                              <div className="col-7">
                                <td
                                  className="price"
                                  data-title="Price"
                                  style={{
                                    backgroundColor: isDisable && "#A4A9A9",
                                  }}
                                >
                                  <p className="text-brand">
                                    $
                                    {(
                                      item.item?.detail.sales_price *
                                      item.quantity
                                    ).toFixed(2)}{" "}
                                  </p>
                                </td>
                              </div>
                              <div className="col-8">
                                <td
                                  className="price"
                                  data-title="Grand Total"
                                  style={{
                                    backgroundColor: isDisable && "#A4A9A9",
                                  }}
                                >
                                  <h4 className="text-brand text-center">
                                    ${totalAmount.toFixed(2)}{" "}
                                  </h4>
                                </td>
                              </div>

                              <div className="col-4">
                                <td className="action delete-cart">
                                  {/* {item.item.stock > 0 ? (
                                    <span className="stock-status in-stock mb-0">
                                      {" "}
                                      In Stock{" "}
                                    </span>
                                  ) : (
                                    <span className="stock-status out-stock mb-0">
                                      {" "}
                                      Stock Out{" "}
                                    </span>
                                  )} */}
                                  {/* <p
                                    // onClick={(e) => handleRemoveItem(e, item)}
                                    onClick={(e) => {
                                      //   setdeleteItem(item);
                                      //   setdeletePopup(true);
                                    }}
                                    className="text-body"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <i className="fi-rs-trash" />
                                  </p> */}
                                </td>
                              </div>
                            </div>
                          </tr>
                          <tr
                            key={index}
                            className="pt-30 mobile-cart cart-table-lg"
                          >
                            {/* <td className="custome-checkbox pl-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id={"cart-ckb-" + index}
                          defaultValue=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"cart-ckb-" + index}
                        />
                      </td> */}
                            <td
                              className="image product-thumbnail pt-40"
                              style={{
                                backgroundColor: isDisable && "#A4A9A9",
                              }}
                              id={`disableIdd${index}`}
                            >
                              <Link
                                style={{ marginLeft: "5px" }}
                                className="m-2"
                                to={
                                  "/product/" +
                                  item.item_id +
                                  "/" +
                                  convertToSlug(item.item.item_name) +
                                  "/" +
                                  true
                                }
                                title={item.item.item_name}
                                state={{ isItem: true }}
                              >
                                <img
                                  width={90}
                                  height={90}
                                  src={
                                    item?.item?.detail?.item_image
                                      ? process.env.REACT_APP_BASE_URL +
                                        "/storage/products/" +
                                        item?.item?.detail?.item_image
                                      : process.env.REACT_APP_BASE_URL +
                                        "/storage/products/no-image.png"
                                  }
                                  alt="#"
                                />
                              </Link>
                            </td>
                            <td
                              className="product-des product-name cart-product-name-wc"
                              style={{
                                backgroundColor: isDisable && "#A4A9A9",
                              }}
                            >
                              <h6 className="mb-5">
                                <Link
                                  className="product-name mb-10 text-heading"
                                  to={
                                    "/product/" +
                                    item.item_id +
                                    "/" +
                                    convertToSlug(item.item.item_name) +
                                    "/" +
                                    true
                                  }
                                  title={titleName}
                                  state={{ isItem: true }}
                                >
                                  {titleName}
                                </Link>
                              </h6>
                              <div className="product-rate-cover">
                                <div className="product-rate d-inline-block">
                                  <div
                                    className="product-rating"
                                    style={{ width: "90%" }}
                                  ></div>
                                </div>
                                <span className="font-small ml-5 text-muted">
                                  {" "}
                                  (4.0)
                                </span>
                                <ul className="">
                                  {item.item?.detail?.brand?.brand_name && (
                                    <li className=" ">
                                      Brand :{" "}
                                      <span className="text-brand mr-3">
                                        {item.item?.detail?.brand?.brand_name}
                                      </span>
                                    </li>
                                  )}
                                  {item?.item?.detail?.product_code && (
                                    <li>
                                      Product Code :{" "}
                                      <span className="text-brand mr-3">
                                        {" "}
                                        {item?.item?.detail?.product_code}
                                      </span>
                                    </li>
                                  )}
                                  {item.item?.detail?.category
                                    ?.category_name && (
                                    <li className=" ">
                                      Category :{" "}
                                      <span className="text-brand mr-3">
                                        {
                                          item.item?.detail?.category
                                            ?.category_name
                                        }
                                      </span>
                                    </li>
                                  )}

                                  {item?.item?.detail?.color?.title && (
                                    <li className=" ">
                                      Color :{" "}
                                      <span className="text-brand mr-3">
                                        {" "}
                                        {item?.item?.detail?.color?.title}
                                      </span>
                                    </li>
                                  )}
                                  {item?.item?.detail?.size?.title && (
                                    <li>
                                      Size :{" "}
                                      <span className="text-brand mr-3">
                                        {" "}
                                        {item?.item?.detail?.size?.title}
                                      </span>
                                    </li>
                                  )}
                                  {/* <li className="">
                                    Stock :
                                    <span className="in-stock text-brand ml-5">
                                      {" "}
                                      {item?.item?.stock} Items In Stock
                                    </span>
                                  </li> */}
                                  {item?.item?.detail?.flavor?.title && (
                                    <li>
                                      Flavor :{" "}
                                      <span className="text-brand">
                                        {" "}
                                        {item?.item?.detail?.flavor?.title}
                                      </span>
                                    </li>
                                  )}
                                  {item.item?.detail?.unit?.unit_name && (
                                    <li className=" ">
                                      Unit :{" "}
                                      <span className="text-brand mr-3">
                                        {item.item?.detail?.unit?.unit_name}
                                      </span>
                                    </li>
                                  )}
                                </ul>
                              </div>
                              {isDisable && (
                                <div
                                  className="alert alert-danger mt-3"
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    gap: "6px",
                                    marginRight: "5px",
                                  }}
                                >
                                  <i className="fi-rs-cross-circle ml-3" />
                                  Stock Out!
                                </div>
                              )}
                            </td>
                            <td
                              className="text-center pt-5"
                              // data-title="Stock"
                            >
                              {/* {item.item.stock > 0 ? (
                                <span className="stock-status in-stock mb-0">
                                  {" "}
                                  In Stock{" "}
                                </span>
                              ) : (
                                <span className="stock-status out-stock mb-0">
                                  {" "}
                                  Stock Out{" "}
                                </span>
                              )} */}
                            </td>
                            <td
                              className="price"
                              data-title="Price"
                              style={{
                                backgroundColor: isDisable && "#A4A9A9",
                              }}
                            >
                              <h6 className="text-body">
                                ${item.item?.detail?.sales_price?.toFixed(2)}
                              </h6>
                              <span className="user-id-font">
                                <span> {userId}</span>
                              </span>
                            </td>
                            <td
                              className="text-center detail-info"
                              data-title="Stock"
                              style={{
                                backgroundColor: isDisable && "#A4A9A9",
                              }}
                            >
                              <div className="detail-extralink mr-15">
                                <div className="detail-qty border radius">
                                  {/* <a href="" className="qty-down">
                                    <i className="fi-rs-angle-small-down" />
                                  </a> */}
                                  {/* <span className="qty-val">{item.quantity}</span> */}
                                  <input
                                    className="qty-val"
                                    type="number"
                                    min={0}
                                    disabled={true}
                                    style={{
                                      border: "none",
                                      maxHeight: "28px",
                                    }}
                                    value={item?.quantity}
                                  />

                                  {/* <a href="" className="qty-up">
                                    <i className="fi-rs-angle-small-up" />
                                  </a> */}
                                </div>
                              </div>
                            </td>
                            <td
                              className="price"
                              data-title="Price"
                              style={{
                                backgroundColor: isDisable && "#A4A9A9",
                              }}
                            >
                              <b className="text-brand">
                                $
                                {(
                                  item.item?.detail.sales_price * item.quantity
                                ).toFixed(2)}{" "}
                              </b>
                            </td>
                            <td
                              className="price"
                              data-title="Price"
                              style={{
                                backgroundColor: isDisable && "#A4A9A9",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "3px",
                                  justifyContent:
                                    !items[index]?.item?.detail?.taxValue &&
                                    "center",
                                }}
                              >
                                {items[index]?.item?.detail?.taxValue
                                  ?.tax_product_amount ? (
                                  <p className="text-brand">
                                    $
                                    {(
                                      items[index]?.item?.detail?.taxValue
                                        ?.tax_product_amount * item.quantity
                                    ).toFixed(2)}
                                  </p>
                                ) : (
                                  <p className="text-brand">NA</p>
                                )}
                                {items[index]?.item?.detail?.taxValue && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        {items[index]?.item?.detail
                                          ?.taxValue ? (
                                          <div className="product-rate-cover">
                                            {/* cart-dropdown-hm2 account-dropdown h-50 */}
                                            <ul>
                                              {items[index]?.item?.detail
                                                ?.taxValue?.name && (
                                                <li className="mr-3">
                                                  <span
                                                    className="text-brand"
                                                    style={{
                                                      minWidth: "65px",
                                                      maxWidth: "80px",
                                                    }}
                                                  >
                                                    Tax Name :
                                                  </span>
                                                  <span>
                                                    {
                                                      items[index]?.item?.detail
                                                        .taxValue?.name
                                                    }
                                                  </span>
                                                </li>
                                              )}
                                              {items[index]?.item?.detail
                                                ?.taxValue?.price_type && (
                                                <li className="mr-3">
                                                  <span
                                                    className="text-brand"
                                                    style={{
                                                      minWidth: "65px",
                                                      maxWidth: "80px",
                                                    }}
                                                  >
                                                    Tax type :
                                                  </span>
                                                  <span>
                                                    {" "}
                                                    {
                                                      items[index]?.item?.detail
                                                        ?.taxValue?.price_type
                                                    }
                                                  </span>
                                                </li>
                                              )}
                                              {items[index]?.item?.detail
                                                ?.taxValue
                                                ?.tax_product_amount && (
                                                <li className="mr-3">
                                                  <span
                                                    className="text-brand"
                                                    style={{
                                                      minWidth: "65px",
                                                      maxWidth: "80px",
                                                    }}
                                                  >
                                                    Tax Amount :
                                                  </span>
                                                  <span>
                                                    {" "}
                                                    {items[
                                                      index
                                                    ]?.item?.detail?.taxValue?.tax_product_amount?.toFixed(
                                                      2
                                                    )}
                                                  </span>
                                                </li>
                                              )}
                                              {items[index]?.item?.detail
                                                ?.taxValue?.tax_unit && (
                                                <li className="mr-3">
                                                  <span
                                                    className="text-brand"
                                                    style={{
                                                      minWidth: "65px",
                                                      maxWidth: "80px",
                                                    }}
                                                  >
                                                    Tax Unit :
                                                  </span>
                                                  <span>
                                                    {" "}
                                                    {
                                                      items[index]?.item?.detail
                                                        ?.taxValue?.tax_unit
                                                    }
                                                  </span>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        ) : (
                                          <span>NA</span>
                                        )}
                                      </Tooltip>
                                    }
                                  >
                                    <i
                                      className="fi-rs-info "
                                      style={{ paddingTop: "5px" }}
                                    />
                                  </OverlayTrigger>
                                )}
                              </div>
                            </td>
                            <td
                              className="price"
                              style={{
                                backgroundColor: isDisable && "#A4A9A9",
                              }}
                            >
                              <h4 className="text-brand text-center">
                                ${totalAmount.toFixed(2)}
                              </h4>
                            </td>
                            <td
                              className="action text-center"
                              style={{
                                backgroundColor: isDisable && "#A4A9A9",
                              }}
                            >
                              {/* <p
                                href=""
                                // onClick={(e) => handleRemoveItem(e, item)}
                                onClick={(e) => {
                                  //   setdeleteItem(item);
                                  //   setdeletePopup(true);
                                }}
                                className="text-body"
                              >
                                <i className="fi-rs-trash" />
                              </p> */}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={7}>
                        {" "}
                        <h5> No Items Available in Cart </h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="divider-2 mb-30" />

            <h6
              className="text-brand mb-2 curser-hover pe-auto"
              onClick={() => {
                // setisAddressPopup(true);
              }}
            >
              <a
                href=""
                onClick={(e) => {
                  e?.preventDefault();
                }}
              >
                {/* Change Address? */}
              </a>
            </h6>
            <div className="cart-action d-flex justify-content-between mb-3"></div>
            <div className="row mt-50 d-none">
              <div className="col-lg-7">
                <div className="calculate-shiping p-40 border-radius-15 border">
                  <h4 className="mb-10">Calculate Shipping</h4>
                  <p className="mb-30">
                    <span className="font-lg text-muted">Flat rate:</span>
                    <strong className="text-brand">5%</strong>
                  </p>
                  <form className="field_form shipping_calculator">
                    <div className="form-row">
                      <div className="form-group col-lg-12">
                        <div className="custom_select">
                          <select
                            className="form-control select-active w-100 select2-hidden-accessible"
                            data-select2-id={7}
                            tabIndex={-1}
                            aria-hidden="true"
                          >
                            <option value="" data-select2-id={9}>
                              United Kingdom{" "}
                            </option>
                          </select>
                          <span
                            className="select2 select2-container select2-container--default"
                            dir="ltr"
                            data-select2-id={8}
                            style={{ width: "520.094px" }}
                          >
                            <span className="selection">
                              <span
                                className="select2-selection select2-selection--single"
                                role="combobox"
                                aria-haspopup="true"
                                aria-expanded="false"
                                tabIndex={0}
                                aria-labelledby="select2-n4i4-container"
                              >
                                <span
                                  className="select2-selection__rendered"
                                  id="select2-n4i4-container"
                                  role="textbox"
                                  aria-readonly="true"
                                  title="United Kingdom"
                                >
                                  United Kingdom
                                </span>
                                <span
                                  className="select2-selection__arrow"
                                  role="presentation"
                                >
                                  <b role="presentation" />
                                </span>
                              </span>
                            </span>
                            <span
                              className="dropdown-wrapper"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-row row">
                      <div className="form-group col-lg-6">
                        <input
                          required="required"
                          placeholder="State / Country"
                          name="name"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <input
                          required="required"
                          placeholder="ZIP"
                          name="name"
                          type="text"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="p-40">
                  <h4 className="mb-10">Apply Coupon</h4>
                  <p className="mb-30">
                    <span className="font-lg text-muted">
                      Using A Promo Code?
                    </span>
                  </p>
                  <form action="#">
                    <div className="d-flex justify-content-between">
                      <input
                        className="font-medium mr-15 coupon"
                        name="Coupon"
                        placeholder="Enter Your Coupon"
                      />
                      <button className="btn">
                        <i className="fi-rs-label mr-10" />
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="border p-md-4 cart-totals ml-12">
              <div className="table-responsive">
                <table className="table no-border">
                  <tbody>
                    <tr>
                      <td className="cart_total_label">
                        <h6 className="text-muted">Subtotal</h6>
                      </td>
                      <td className="cart_total_amount">
                        <h4 className="text-brand text-end">${subtotal}</h4>
                      </td>
                    </tr>

                    <tr>
                      <td className="cart_total_label">
                        <h6 className="text-muted">Tax</h6>
                      </td>
                      <td className="cart_total_amount">
                        <h4 className="text-brand text-end">
                          ${totalTaxAmount || 0}
                        </h4>
                      </td>
                    </tr>
                    {/* <tr>
                  <td scope="col" colSpan={2}>
                    <div className="divider-2 mt-10 mb-10" />
                  </td>
                </tr>
                <tr>
                  <td className="cart_total_label">
                    <h6 className="text-muted">Shipping</h6>
                  </td>
                  <td className="cart_total_amount">
                    <h5 className="text-heading text-end">Free </h5>
                  </td>
                </tr>
                <tr>
                  <td className="cart_total_label">
                    <h6 className="text-muted">Estimate for</h6>
                  </td>
                  <td className="cart_total_amount">
                    <h5 className="text-heading text-end">United Kingdom </h5>
                  </td>
                </tr> */}
                    <tr>
                      <td scope="col" colSpan={2}>
                        <div className="divider-2 mt-10 mb-10" />
                      </td>
                    </tr>
                    <tr>
                      <td className="cart_total_label">
                        <h6 className="text-muted">Grand total</h6>
                      </td>
                      <td className="cart_total_amount">
                        <h4 className="text-brand text-end">
                          ${total?.toFixed(2)}
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* {addressDetails.addresses?.length && (
            <ConfirmationPopupModal
              title={
                "Your Address is selected as default address ,if you want to change it you can change"
              }
              // content={`Your Address is selected as default address ,if you want to change it you can change`}
              show={isAddressPopup}
              addressData={addressDetails}
              setShow={setisAddressPopup}
              isAddress={true}
              addressId={addressId}
              addre
              setaddressId={setaddressId}
              onClose={onCloseAddressPopup}
              confirmClicked={(e) => {
                updateAddressValue();
              }}
              addressNum={addressNum}
              setaddressNum={setaddressNum}
            />
          )} */}
        </div>
      </div>
    </main>
  );
};

export default OrderDetails;
