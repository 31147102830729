import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader";

let i_s = 1;
const Slider2 = ({ image, isLoading }) => {
  const [sliderIndex, setSliderIndex] = useState(i_s);

  useEffect(() => {
    setIndex();
  }, []);
  const setIndex = () => {
    setSliderIndex(i_s === 1 ? 2 : 1);
    i_s = i_s === 1 ? 2 : 1;

    setTimeout(setIndex, 5000);
  };
  let ele = document?.getElementById("no_right_click");
  ele?.addEventListener("contextmenu", (ev) => {
    ev?.preventDefault(); // this will prevent browser default behavior
  });
  let elee = document?.getElementById("no_rightclick");
  elee?.addEventListener("contextmenu", (ev) => {
    ev?.preventDefault(); // this will prevent browser default behavior
  });

  return (
    <section
      className="home-slider style-2 position-relative "
      style={{ marginBottom: "100px" }}
    >
      <div className="container">
        {!isLoading ? (
          <div className="row">
            <div className="col-xl-8 col-lg-12" id="no_right_click">
              <div className="home-slide-cover">
                <div className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
                  <div
                    className="single-hero-slider single-animation-wrap"
                    style={{
                      backgroundImage: image
                        ? `url(${
                            process.env.REACT_APP_BASE_URL + "/storage/" + image
                          })`
                        : "url(/images/banner/3leftTop.jpg)",
                      // backgroundImage: "url(/images/banner/3leftTop.jpg)",
                      display: sliderIndex == 1 ? "block" : "none",
                    }}
                  >
                    <div className="slider-content">
                      <h1 className="display-2 mb-40">
                        {/* Pure Coffe
                                            <br />
                                            Big discount */}
                      </h1>
                      <Link href="" className="btn btn-xs">
                        Shop Now <i className="fi-rs-arrow-small-right" />
                      </Link>
                      {/* <form className="form-subcriber d-none">
                      <input type="email" placeholder="Your emaill address" />
                      <button className="btn" type="button">
                        Subscribe
                      </button>
                    </form> */}
                    </div>
                  </div>
                  <div
                    className="single-hero-slider single-animation-wrap"
                    style={{
                      // backgroundImage: "url(/images/banner/3leftTop.jpg)",
                      backgroundImage: image
                        ? `url(${
                            process.env.REACT_APP_BASE_URL + "/storage/" + image
                          })`
                        : "url(/images/banner/3leftTop.jpg)",

                      display: sliderIndex == 2 ? "block" : "none",
                    }}
                  >
                    <div className="slider-content">
                      {/* <h1 className="display-2 mb-40">
                                            Snacks box
                                            <br />
                                            daily save
                                        </h1>
                                        <p className="mb-65">Sign up for the daily newsletter</p>
                                        <form className="form-subcriber d-none">
                                            <input type="email" placeholder="Your emaill address" />
                                            <button className="btn" type="button">
                                                Subscribe
                                            </button>
                                        </form> */}
                    </div>
                  </div>
                </div>
                <div className="slider-arrow hero-slider-1-arrow" />
              </div>
            </div>
            <div className="col-lg-4 d-none d-xl-block" id="no_rightclick">
              <div className="banner-img style-3 animated animated">
                <div className="banner-text ">
                  <h2 className="mb-50">
                    {/* Delivered <br />
                                    to */}
                    <span className="text-brand">
                      {/* your
                                        <br />
                                        home */}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </section>
  );
};

export default Slider2;
